import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { TextRenderer } from "../common/text-renderer";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    paper: {
      backgroundColor: theme.palette.secondary.main,
      margin: "0.5rem",
      borderRadius: 5,
      maxHeight: "40%",
      opacity: "0.95",
      [theme.breakpoints.up("md")]: {
        width: "20%",
        left: "unset",
        right: "1.5rem",
        bottom: "1.5rem",
        maxHeight: "80%",
      },
    },
    list: {
      backgroundColor: theme.palette.secondary.main,
      padding: "0.5rem 0.7rem 0.25rem",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    superIndex: {
      display: "flex",
      color: "white",
      fontSize: 8,
    },
    articleTitle: {
      color: "white",
      fontSize: 12,
      fontWeight: "bold",
    },
  })
);

const Footnotes: React.FC<{
  titles: string[];
  content: string[][];
  setOpen: (open: boolean) => void;
}> = ({ titles, content, setOpen }) => {
  const { paper, list, superIndex, articleTitle, root } = useStyles();

  return (
    <div>
      <Drawer
        anchor={"bottom"}
        variant="temporary"
        open={true}
        onClose={() => setOpen(false)}
        classes={{
          paper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        BackdropProps={{
          classes: {
            root: root,
          },
        }}
      >
        {content.map((articleFootnotes, idx) => (
          <Box
            className={list}
            onClick={() => setOpen(false)}
            onKeyDown={() => setOpen(false)}
          >
            <div className={articleTitle}>
              {content[idx].length === 1 ? "" : titles[idx]}
            </div>
            {articleFootnotes.map((footnote, idx) => (
              <div className={superIndex}>
                {articleFootnotes.length === 1 ? "" : idx + 1}
                <TextRenderer
                  inverseColor
                  smallText
                  data={JSON.parse(footnote)}
                />
              </div>
            ))}
          </Box>
        ))}
      </Drawer>
    </div>
  );
};

export default Footnotes;
