import React, { useEffect, useState } from "react";
import SEO from "../components/seo";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import footnoteIcon from "../assets/images/btn_footnote.svg";
import { ThemeProvider } from "@material-ui/core/styles";
import { TextRenderer } from "../components/common/text-renderer";
import Footnotes from "../components/content/footnotes";
import { graphql } from "gatsby";
import Frame from "../components/common/frame";
import { theme } from "../components/common";
import { ImageProvider } from "../components/common";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    contentWrapper: {
      padding: theme.spacing(2),
      paddingLeft: "1.2rem",
      [theme.breakpoints.up("md")]: {
        width: "55%",
        paddingLeft: "0.5rem",
      },
      [theme.breakpoints.up("lg")]: {
        width: "73%",
      },
    },
    footnoteButton: {
      width: "3rem",
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      filter: "drop-shadow(4px 4px 4px grey)",
      [theme.breakpoints.up("md")]: {
        width: "4rem",
        right: "2rem",
        bottom: "2rem",
      },
    },
    flexWrapper: {
      display: "flex",
      flexDirection: "row",
    },
    main: {
      marginTop: theme.spacing(8),
      width: "100vw",
    },
  })
);

export type TemplateProps = {
  pageContext: {
    slug: string;
    categoriesOrder: string[];
  };
  data: {
    content: {
      title: string;
      mainText: {
        references: {
          footnoteText: {
            raw: string;
          };
          title: string;
        }[];
        raw: string;
      };
      image: {
        file: {
          url: string;
        };
      };
      articles: {
        id: string;
        title: string;
        text: {
          raw: string;
          references: {
            title: string;
            footnoteText: {
              raw: string;
            };
          }[];
        };
        seoMetadata: {
          keywords: string[];
        };
      }[];
      node_locale: string;
    };
    footnotes: {
      nodes: {
        contentful_id: string;
        id: string;
        footnoteText: { raw: string };
      }[];
    };
    about: { slug: string };
    categories: { nodes: { slug: string; contentful_id: string }[] };
  };
};

const SubPage = ({
  data: { content, footnotes, categories, about },
  pageContext: { slug, categoriesOrder },
}: TemplateProps) => {
  const mainText = content.mainText?.raw;
  const { articles } = content;
  const keywordsArrays = articles.map(article => article.seoMetadata?.keywords);
  const keywords = _.flatten(keywordsArrays);

  const [openFootnotes, setOpen] = useState(false);
  const [selectedFootnote, setSelectedFootnote] = useState<string | null>(null);
  const [articlesFootnotes, setArticlesFootnotes] = useState<string[][]>(
    articles.map(article =>
      article.text.references
        .filter(ref => !!ref.footnoteText?.raw)
        .map(ref => ref.footnoteText?.raw)
    )
  );

  const articlesTitles = articles.map(article => article.title);
  const setOpenFootnotes = (open: boolean) => {
    setOpen(open);
    if (!open) {
      setSelectedFootnote(null);
    }
  };
  useEffect(() => {
    if (selectedFootnote) {
      let footnote = footnotes.nodes.find(
        ({ contentful_id }) => selectedFootnote === contentful_id
      );
      setArticlesFootnotes(footnote ? [[footnote?.footnoteText?.raw]] : []);
      setOpenFootnotes(true);
    } else {
      setArticlesFootnotes(
        articles.map(article =>
          article.text.references
            .filter(ref => !!ref.footnoteText?.raw)
            .map(ref => ref.footnoteText?.raw)
        )
      );
    }
  }, [selectedFootnote]);
  const {
    contentWrapper,
    footnoteButton: footnoteButton,
    main,
    root,
  } = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={root}>
        <SEO keywords={keywords} url={slug} />
        <Frame
          slug={slug}
          about={about}
          categories={categoriesOrder.map(
            cat =>
              categories.nodes.find(
                ({ contentful_id }) => contentful_id === cat
              )?.slug || ""
          )}
        />
        <main className={main}>
          <Box className={contentWrapper}>
            {mainText && <TextRenderer data={JSON.parse(mainText)} />}
            {articles.map(({ title, text }, idx) => (
              <div key={idx}>
                {/* <h2>{title}</h2> */}
                <TextRenderer
                  data={JSON.parse(text.raw)}
                  footnoteCallback={setSelectedFootnote}
                />

                {title === "Handlungsempfehlungen" && (
                  <span>
                    <ImageProvider fileName="poster_handlungsstrategien.jpg" />
                  </span>
                )}
              </div>
            ))}
          </Box>
          {openFootnotes && (
            <>
              <Box>
                <Footnotes
                  titles={articlesTitles}
                  content={articlesFootnotes}
                  setOpen={setOpenFootnotes}
                />
              </Box>
            </>
          )}
          {openFootnotes || articlesFootnotes[0].length === 0 ? null : (
            <Button
              className={footnoteButton}
              aria-label="Fußnoten öffnen, open footnotes"
              onClick={() => setOpenFootnotes(true)}
            >
              <img src={footnoteIcon} />
            </Button>
          )}
        </main>
      </div>
    </ThemeProvider>
  );
};

export const query = graphql`
  query($slug: String!, $locale: String!) {
    content: contentfulSubPage(slug: { eq: $slug }) {
      title
      image {
        file {
          url
        }
      }
      articles {
        id
        title
        text {
          raw
          references {
            title
            footnoteText {
              raw
            }
          }
        }
        seoMetadata {
          keywords
        }
      }
      node_locale
    }
    footnotes: allContentfulFootnote {
      nodes {
        id
        contentful_id
        footnoteText {
          raw
        }
      }
    }
    about: contentfulAboutPage(node_locale: { eq: $locale }) {
      slug
    }
    categories: allContentfulSubPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        slug
      }
    }
  }
`;
export default SubPage;
